import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ProductView from "../components/product-item-view"

import { normalizedProducts, Product } from "../models/product"

import { FlexibleRowContainer } from "../styles/components/shared-styles"

import * as bundleProductsStyles from "../styles/components/bundle-products.module.scss"

const FeaturedBundleProducts = () => {
  const data = useStaticQuery(
    graphql`
      query ProductsQuery {
        allProductsJson {
          nodes {
            slug
            name
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            screenshotImages {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        allStripePrice(filter: { active: { eq: true } }) {
          nodes {
            id
            unit_amount
            currency
            product {
              metadata {
                slug
              }
              name
            }
          }
        }
      }
    `
  )

  const allLocalProductsData = data.allProductsJson.nodes
  const allStripeProductsData = data.allStripePrice.nodes

  const products = normalizedProducts(
    allLocalProductsData,
    allStripeProductsData
  )

  return (
    <FlexibleRowContainer className={bundleProductsStyles.productsContainer}>
      {products.map((product: Product) => {
        return <ProductView product={product} />
      })}
    </FlexibleRowContainer>
  )
}

export default FeaturedBundleProducts
