import React from "react"

import { FaInstagram, FaGithub, FaLinkedinIn } from "react-icons/fa"

import * as styles from "../styles/components/hero.module.scss"
import { Icon, PrimaryButton } from "../styles/components/shared-styles"

import { StaticImage } from "gatsby-plugin-image"

type HeroProps = {
  onPrimaryButtonClicked: () => void
}

const staticImageProps = {
  alt: "Connor Black Portrait",
  placeholder: "blurred",
}

const Hero = (props: HeroProps, className: any) => (
  <section className={styles.sectionContainer}>
    <div className={styles.imageContainer}>
      <StaticImage
        src="../assets/images/transparent-portrait.png"
        {...staticImageProps}
      />
    </div>

    <div className={styles.metaContainer}>
      <h1>Hi There,</h1>
      <p>
        I’m Connor, a professional iOS mobile app developer who will help your
        business succeed in the digital world.
      </p>
      <div className={styles.actionsContainer}>
        <Icon
          className={styles.instagram}
          href="https://www.instagram.com/blacksapps/"
          rel="noreferrer"
          target="_blank"
        >
          <FaInstagram size={25} />
        </Icon>

        <Icon
          className={styles.github}
          href="https://github.com/connorb645"
          rel="noreferrer"
          target="_blank"
        >
          <FaGithub size={25} />
        </Icon>

        <Icon
          className={styles.linkedin}
          href="https://www.linkedin.com/in/connorrblack/"
          rel="noreferrer"
          target="_blank"
        >
          <FaLinkedinIn size={25} />
        </Icon>

        <PrimaryButton onClick={props.onPrimaryButtonClicked}>
          Let's work together!
        </PrimaryButton>
      </div>
    </div>
  </section>
)

export default Hero
