import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import Hero from "../components/hero"
import SectionHeader from "../components/section-header"
import RecentArticles from "../components/recent-articles"
import FeaturedBundleProducts from "../components/bundle-products"
import ContactForm from "../components/contact-form"

import { FaApple } from "react-icons/fa"
import { MdOutlineArticle, MdOutlineContactMail } from "react-icons/md"

import scrollTo from "gatsby-plugin-smoothscroll"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />

      <Hero onPrimaryButtonClicked={() => scrollTo("#contact")} />

      <section>
        <SectionHeader
          topTitle="Get a head-start with beafutiful, production quality and customisable app templates"
          middleTitle="iOS App Templates"
          bottomTitle="Most Popular"
        >
          <MdOutlineArticle size={20} />
        </SectionHeader>
        <FeaturedBundleProducts />
      </section>

      <section>
        <SectionHeader
          topTitle="Freelance"
          middleTitle="iOS Developer"
          bottomTitle="Enthusiast, and Creator"
        >
          <FaApple size={20} />
        </SectionHeader>
        <p>
          I’m Connor, a freelancer that provides creative and professional iOS
          development for clients in and around Newcastle Upon Tyne, Tyne and
          Wear & the rest of the UK. I have a strong passion for working with
          startups and SME’s to help boost their brand and digital presence.
        </p>
      </section>

      <section>
        <SectionHeader
          topTitle="Here is my effort to give back to the community"
          middleTitle="Articles"
          bottomTitle="Most Recent"
        >
          <MdOutlineArticle size={20} />
        </SectionHeader>
        <RecentArticles />
      </section>

      {/* <section id='portfolio'>
        <SectionHeader topTitle='Take a look at what I&apos;ve been up to' middleTitle='iOS Portfolio' bottomTitle='Most Recent' >
          <BsPhone size={20}/>
        </SectionHeader>
        <FeaturedPortfolios />
      </section> */}

      <section id="contact">
        <SectionHeader
          topTitle="Want to work together?"
          middleTitle="Drop me a line!"
          bottomTitle="I'll reply within 24 hours"
        >
          <MdOutlineContactMail size={20} />
        </SectionHeader>
        <ContactForm />
      </section>
    </Layout>
  )
}

export default IndexPage
