import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import * as recentArticlesStyles from "../styles/components/recent-articles.module.scss"

const RecentArticles = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "article" } } }
        limit: 3
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              shortDescription
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <ol>
        {data.allMarkdownRemark.edges.map((edge: any) => {
          const slug = `articles/${edge.node.fields.slug}`
          return (
            <Link key={slug} to={slug}>
              <div className={recentArticlesStyles.container}>
                <li>
                  <h2>{edge.node.frontmatter.title}</h2>
                  <p className={recentArticlesStyles.dateStyle}>
                    Published on {edge.node.frontmatter.date}
                  </p>
                  <p>{edge.node.frontmatter.shortDescription}</p>
                </li>
              </div>
            </Link>
          )
        })}
      </ol>
    </div>
  )
}

export default RecentArticles
